$imgPath: '/img/root/';

body {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: #182B53;
}
input:focus {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  visibility: hidden;
}
input[type=number] {
  -moz-appearance: textfield;
}

.h2 {
  font-size: 36px;
  font-weight: 600;
}

.h3 {
  font-size: 36px;
  font-weight: 600;
}

.h4 {
  font-size: 24px;
  font-weight: 600;
}

.fz-24 {
  font-size: 24px;
}

.text-blue {
  color: #182B53;
}

.text-gray {
  color: #5C5C5C !important;
}

.bg-primary {
  background-color: #182B53 !important;
}

.mb-32, .my-32, .m-32 {
  margin-bottom: 2rem;
}
.mt-32, .my-32, .m-32 {
  margin-top: 2rem;
}
.ml-32, .mx-32, .m-32 {
  margin-left: 2rem;
}
.mr-32, .mx-32, .m-32 {
  margin-right: 2rem;
}
.pb-32, .py-32, .p-32 {
  padding-bottom: 2rem;
}
.pt-32, .py-32, .p-32 {
  padding-top: 2rem;
}
.pl-32, .px-32, .p-32 {
  padding-left: 2rem;
}
.pr-32, .px-32, .p-32 {
  padding-right: 2rem;
}

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 18px;
  border: none;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  &#{&}-primary {
    background-color: #182B53 !important;
    color: #fff !important;
    &:hover {
      background-color: #fff !important;
      color: #182B53 !important;
    }
  }
  &#{&}-primary-outline {
    background-color: #FFFFFF !important;
    color: #182B53 !important;
    &:hover {
      background-color: #182B53 !important;
      color: #fff !important;
    }
  }
  &#{&}-link {
    color: #182B53 !important;
    padding: 10px !important;
    border: 1px solid #fff !important;
    border-bottom-color: currentColor !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    transition: all 5ms;
    &:hover {
      padding: 10px 16px !important;
      border-color: currentColor !important;
      border-radius: 10px !important;
      margin-right: -6px;
      text-decoration: none;
      animation: .05;
    }
  }
}

.dropdown-toggle::after {
  content: none;
}

.dropdown-menu {
  border-radius: 20px;
  width: calc(100vw - 32px);
}

.dropdown-item {
  white-space: normal;
  &:hover,
  &.active,
  &:active {
    color: #27AAE1 !important;
    background: none;
  }
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  &-label {
    margin-bottom: 0;
    line-height: 1;
    cursor: pointer;
    user-select: none;
  }
  &-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &-mark {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 4px;
      background: url('/img/checkbox.svg') no-repeat center/contain;
    }
    &::after {
      content: '';
      position: absolute;
      top: -10%;
      left: 10%;
      width: 100%;
      height: 100%;
    }
  }
  &-input:checked ~ &-mark::after {
    background: url('/img/check-solid.svg') no-repeat center/contain;
  }
  &-sm &-mark {
    width: 15px;
    height: 15px;
  }
  &-lg &-mark {
    width: 40px;
    height: 40px;
  }
}

.shadow {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25) !important;
}

.form-group.inline {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.form-control {
  border-radius: 10px;
}

.border.rounded {
  border-radius: 10px !important;
  &-20 {
    border-color: currentColor !important;
    border-radius: 20px;
  }
}

.bt {
  border-top: 1px solid currentColor;
}

.bb {
  border-bottom: 1px solid currentColor;
  &:last-child {
    border: none;
  }
}

.br {
  border-right: 1px solid currentColor;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-55 {
  width: 55%;
}

.footer {
  background-color: #182B53;
  color: #FFFFFF;
}

.cursor-pointer {
  cursor: pointer;
}

.muted-block {
  opacity: .4;
  filter: grayscale(1);
  transition: all 0.2s ease;
}

.result-block {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  &.fade:not(.show) {
    z-index: -1;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25%;
  }
  .w-md-100 {
    width: 100%;
  }
  .dropdown-menu {
    max-width: 525px;
  }
  .send-form {
    margin-right: 190px;
    &-wrap {
      border: 1px solid #C9C9C9;
      border-radius: 10px;
    }
  }
}

@media (min-width: 1024px) {
  .btn {
    white-space: nowrap
  }
  .h2 {
    font-size: 52px;
  }
  .h3 {
    font-size: 44px;
  }
}

@media (min-width: 1280px) {

}

// Loader styles
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  &::before,
  &::after {
    position: absolute;
    content: '';
  }
  &::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #fff;
  }
  &::after {
    z-index: 2;
    left: calc(50% - 32px);
    top: calc(50% - 32px);
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #000 transparent #000 transparent;
    animation: loader 1.2s linear infinite;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// Loader styles end