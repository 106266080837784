body {
  font: normal 400 18px "Quicksand",sans-serif;
  font-optical-sizing: auto;
  color: #182b53;
}

input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  visibility: hidden;
}

input[type=number] {
  -moz-appearance: textfield;
}

.h2 {
  font-size: 36px;
  font-weight: 600;
}

.h3 {
  font-size: 36px;
  font-weight: 600;
}

.h4 {
  font-size: 24px;
  font-weight: 600;
}

.fz-24 {
  font-size: 24px;
}

.text-blue {
  color: #182b53;
}

.text-gray {
  color: #5c5c5c !important;
}

.bg-primary {
  background: #182b53 !important;
}

.mb-32,
.my-32,
.m-32 {
  margin-bottom: 2rem;
}

.mt-32,
.my-32,
.m-32 {
  margin-top: 2rem;
}

.ml-32,
.mx-32,
.m-32 {
  margin-left: 2rem;
}

.mr-32,
.mx-32,
.m-32 {
  margin-right: 2rem;
}

.pb-32,
.py-32,
.p-32 {
  padding-bottom: 2rem;
}

.pt-32,
.py-32,
.p-32 {
  padding-top: 2rem;
}

.pl-32,
.px-32,
.p-32 {
  padding-left: 2rem;
}

.pr-32,
.px-32,
.p-32 {
  padding-right: 2rem;
}

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 18px;
  border: none;
  box-shadow: 4px 4px 15px rgba(0,0,0,.25);
}

.btn.btn-primary {
  background: #182b53 !important;
  color: #fff !important;
}

.btn.btn-primary:hover {
  background: #fff !important;
  color: #182b53 !important;
}

.btn.btn-primary-outline {
  background: #fff !important;
  color: #182b53 !important;
}

.btn.btn-primary-outline:hover {
  background: #182b53 !important;
  color: #fff !important;
}

.btn.btn-link {
  color: #182b53 !important;
  padding: 10px !important;
  border: 1px solid #fff !important;
  border-bottom-color: currentColor !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  transition: all 5ms;
}

.btn.btn-link:hover {
  padding: 10px 16px !important;
  border-color: currentColor !important;
  border-radius: 10px !important;
  margin-right: -6px;
  text-decoration: none;
  animation: .05;
}

.dropdown-toggle::after {
  content: none;
}

.dropdown-menu {
  border-radius: 20px;
  width: calc(100vw - 32px);
}

.dropdown-item {
  white-space: normal;
}

.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  color: #27aae1 !important;
  background: none;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox-label {
  margin-bottom: 0;
  line-height: 1;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox-mark {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}

.custom-checkbox-mark::before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 4px;
  background: url("/img/checkbox.svg") no-repeat center/contain;
}

.custom-checkbox-mark::after {
  content: "";
  position: absolute;
  top: -10%;
  left: 10%;
  width: 100%;
  height: 100%;
}

.custom-checkbox-input:checked~.custom-checkbox-mark::after {
  background: url("/img/check-solid.svg") no-repeat center/contain;
}

.custom-checkbox-sm .custom-checkbox-mark {
  width: 15px;
  height: 15px;
}

.custom-checkbox-lg .custom-checkbox-mark {
  width: 40px;
  height: 40px;
}

.shadow {
  box-shadow: 4px 4px 15px rgba(0,0,0,.25) !important;
}

.form-group.inline {
  margin-left: .5rem;
  margin-right: .5rem;
}

.form-group.inline:first-child {
  margin-left: 0;
}

.form-group.inline:last-child {
  margin-right: 0;
}

.form-control {
  border-radius: 10px;
}

.border.rounded {
  border-radius: 10px !important;
}

.border.rounded-20 {
  border-color: currentColor !important;
  border-radius: 20px;
}

.bt {
  border-top: 1px solid currentColor;
}

.bb {
  border-bottom: 1px solid currentColor;
}

.bb:last-child {
  border: none;
}

.br {
  border-right: 1px solid currentColor;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-55 {
  width: 55%;
}

.footer {
  background: #182b53;
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

.muted-block {
  opacity: .4;
  filter: grayscale(1);
  transition: all .2s ease;
}

.result-block {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.result-block.fade:not(.show) {
  z-index: -1;
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25%;
  }

  .w-md-100 {
    width: 100%;
  }

  .dropdown-menu {
    max-width: 525px;
  }

  .send-form {
    margin-right: 190px;
  }

  .send-form-wrap {
    border: 1px solid #c9c9c9;
    border-radius: 10px;
  }
}

@media (min-width: 1024px) {
  .btn {
    white-space: nowrap;
  }

  .h2 {
    font-size: 52px;
  }

  .h3 {
    font-size: 44px;
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.loader::before,
.loader::after {
  position: absolute;
  content: "";
}

.loader::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #fff;
}

.loader::after {
  z-index: 2;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid;
  border-color: #000 rgba(0,0,0,0) #000 rgba(0,0,0,0);
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}